import { FC, useState } from 'react'
import {
  Control,
  Controller,
  FieldPath,
  FormState,
  UseFormTrigger
} from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { IRouterWithID } from '~/core/@types/global'
import { ROLE } from '~/core/constants/role'
import { openAlert } from '~/core/ui/AlertDialog'
import { LockIcon } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import {
  InlineEditingInput,
  InlineEditingInputLink,
  InlineEditingPhoneNumber,
  InlineEditingViewLink
} from '~/core/ui/InlineEditing'
import { InlineEditingNoActionsAsyncSingleSearchWithSelect } from '~/core/ui/InlineEditingNoActions'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { adminAndMemberCanAction } from '~/core/utilities/permission'
import { useDetectCountryCodeFromTimeZone } from '~/lib/countries-mapping/hooks/use-detect-country-code-from-time-zone'
import useCandidateProfile from '~/lib/features/candidates/hooks/use-query-candidate'
import {
  CandidateProfileInputType,
  ICandidateProfile,
  IUploadDrawerJob
} from '~/lib/features/candidates/types'
import { permittedFieldsManagement } from '~/lib/features/candidates/utilities'
import useBoundStore from '~/lib/store'
import DisableControlByPermission from '../DisableControlByPermission'

const ContactDetailsView: FC<{
  data: ICandidateProfile
  control: Control<any, any>
  trigger: UseFormTrigger<any>
  submit?: () => Promise<void>
  submitPartialField: (
    fieldName: FieldPath<CandidateProfileInputType>,
    validate: () => Promise<boolean>,
    submit?: () => Promise<void>
  ) => Promise<boolean>
  formState: FormState<CandidateProfileInputType>
  configHide?: {
    [key: string]: boolean
  }
  isDrawer?: boolean
  applicantId?: IRouterWithID
  uploadDrawerJobId?: IUploadDrawerJob
  setUploadDrawerJobId?: any
}> = ({
  data,
  control,
  trigger,
  submit,
  submitPartialField,
  formState,
  configHide = {},
  isDrawer = false,
  applicantId,
  uploadDrawerJobId,
  setUploadDrawerJobId
}) => {
  // const router = useRouter()
  const { t } = useTranslation()
  const { promiseCountryStateOptions, promiseJobApplicableListOptions } =
    useCandidateProfile({})
  const [locationMenuOpen, setLocationMenuOpen] = useState<boolean>(false)
  const permittedFields = data?.permittedFields
  const { currentRole } = useBoundStore()
  const { countryCode } = useDetectCountryCodeFromTimeZone()

  const getDisabledLinks = ({
    links = undefined
  }: {
    links?: {
      [key: string]: Array<string>
    }
  }) => {
    if (!links || Object.keys(links).length === 0) return false

    let count = 0
    Object.keys(links).map((item) => {
      count += links[item].length
    })

    if (count >= 15) return true
    return false
  }
  return (
    <div className="grid grid-cols-[108px_1fr] desktop:grid-cols-[224px_1fr]">
      {!configHide.job ? (
        <>
          <div className="flex w-[224px] items-center space-x-2">
            <IconWrapper
              size={16}
              name="Briefcase"
              className="hidden text-gray-600 desktop:block"
            />
            <TypographyText className="text-sm text-gray-700">
              {t('candidates:tabs:candidateOverview:contactDetails:job')}
            </TypographyText>
          </div>
          <div className="ml-2.5 w-full">
            <Controller
              control={control}
              name="jobs"
              render={({ field: { onChange, value: job } }) => {
                const findDefaultValue = uploadDrawerJobId?.find(
                  (item) => !!item.defaultValue
                )

                return (
                  <FormControlItem>
                    {!!findDefaultValue?.jobId ? (
                      <Tooltip content={job?.[0]?.supportingObj?.name}>
                        <div className="line-clamp-1 flex items-center px-2 py-1.5 text-sm text-gray-900">
                          {job?.[0]?.supportingObj?.name}
                        </div>
                      </Tooltip>
                    ) : (
                      <InlineEditingNoActionsAsyncSingleSearchWithSelect
                        onChange={(newValue) => {
                          const objNewValue = {
                            profileId: data.id,
                            jobId: Number(newValue?.[0]?.value) || undefined,
                            jobTitle: newValue?.[0]?.supportingObj?.name
                          }
                          onChange(newValue?.length ? [newValue[0]] : [])

                          const cloneData = JSON.parse(
                            JSON.stringify(uploadDrawerJobId || [])
                          )

                          const findIndexItem = cloneData.findIndex(
                            (fi: { profileId: string }) =>
                              Number(fi.profileId) === Number(data.id)
                          )

                          if (findIndexItem !== -1) {
                            cloneData[findIndexItem] = objNewValue
                            setUploadDrawerJobId &&
                              setUploadDrawerJobId([...cloneData])
                          } else {
                            setUploadDrawerJobId &&
                              setUploadDrawerJobId([...cloneData, objNewValue])
                          }

                          return Promise.resolve(true)
                        }}
                        configSelectOption={{
                          supportingText: [
                            'name',
                            'shortName',
                            'description',
                            'descriptionHelpName'
                          ]
                        }}
                        promiseOptions={promiseJobApplicableListOptions}
                        isClearable={true}
                        placeholder={
                          t(
                            'candidates:tabs:candidateOverview:profileInformation:selectJob'
                          ) as string
                        }
                        size="sm"
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                        value={job?.[0]?.value ? job : []}>
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {job?.[0]?.value ? (
                            <Tooltip content={job?.[0]?.supportingObj?.name}>
                              <div className="line-clamp-1 text-gray-900">
                                {job?.[0]?.supportingObj?.name}
                              </div>
                            </Tooltip>
                          ) : (
                            <div className="text-gray-600">
                              {t(
                                'candidates:tabs:candidateOverview:profileInformation:selectJob'
                              )}
                            </div>
                          )}
                        </div>
                      </InlineEditingNoActionsAsyncSingleSearchWithSelect>
                    )}
                  </FormControlItem>
                )
              }}
            />
          </div>
        </>
      ) : null}

      {!configHide.email ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.email?.roles) ||
              permittedFields?.email
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Mail"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t('candidates:tabs:candidateOverview:contactDetails:email')}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.email?.roles?.includes(ROLE.Everyone) &&
                  !permittedFields?.email?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.email?.client_user_visibility === false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.email?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            <div>
              <div className="ml-2.5 w-full">
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem>
                        <DisableControlByPermission
                          disabled={data?.employeeId ? true : false}
                          applicantId={applicantId}
                          text={
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {permittedFields?.email?.value &&
                              permittedFields?.email?.value?.length > 0 ? (
                                <Tooltip
                                  classNameConfig={{
                                    content: `${
                                      data?.employeeId ? '' : 'hidden'
                                    }`
                                  }}
                                  content={`${t(
                                    `tooltip:thisFieldIsBlocked`
                                  )}`}>
                                  <div className="text-gray-900">
                                    {permittedFields?.email?.value?.[0]}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:notAvailable'
                                  )}
                                </div>
                              )}
                            </div>
                          }>
                          <InlineEditingInput
                            onChange={(newValue) => {
                              onChange(newValue ? [newValue] : [])
                              return submitPartialField(
                                'email',
                                trigger,
                                submit
                              )
                            }}
                            onCancelUpdate={(newValue) =>
                              onChange(newValue ? [newValue] : [])
                            }
                            placeholder={
                              t(
                                'candidates:tabs:candidateOverview:contactDetails:addEmail'
                              ) as string
                            }
                            size="sm"
                            className="-mx-px"
                            value={
                              permittedFields?.email?.value &&
                              permittedFields?.email?.value?.length > 0
                                ? permittedFields?.email?.value?.[0]
                                : undefined
                            }
                            tooltipActionCancel={{
                              title: `${t('button:cancel')}`
                            }}
                            tooltipActionSave={{
                              title: `${t('button:saveEnter')}`
                            }}
                            destructiveText={
                              formState?.errors?.email?.message as string
                            }>
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {data?.email && data?.email?.length > 0 ? (
                                <div className="text-gray-900">
                                  {data.email[0]}
                                </div>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:contactDetails:addEmail'
                                  )}
                                </div>
                              )}
                            </div>
                          </InlineEditingInput>
                        </DisableControlByPermission>
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </div>
          </If>
        </>
      ) : null}

      {!configHide.phone ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.phoneNumber?.roles) ||
              permittedFields?.phoneNumber
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Phone"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:contactDetails:phoneNumber'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.phoneNumber?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.phoneNumber?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.phoneNumber?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.phoneNumber?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            <div>
              <div className="ml-2.5 w-full">
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem>
                        <DisableControlByPermission
                          applicantId={applicantId}
                          text={
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {permittedFields?.phoneNumber?.value &&
                              permittedFields?.phoneNumber?.value?.length >
                                0 ? (
                                <div className="text-gray-900">
                                  {permittedFields.phoneNumber.value[0]}
                                </div>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:notAvailable'
                                  )}
                                </div>
                              )}
                            </div>
                          }>
                          <InlineEditingPhoneNumber
                            country={countryCode}
                            onChange={(newValue) => {
                              onChange(newValue ? [newValue] : [])

                              return submitPartialField(
                                'phoneNumber',
                                trigger,
                                submit
                              )
                            }}
                            onCancelUpdate={(newValue) => {
                              onChange(newValue ? [newValue] : [])
                            }}
                            placeholder={
                              t(
                                'candidates:tabs:candidateOverview:contactDetails:addPhoneNumber'
                              ) as string
                            }
                            size="sm"
                            value={value && value[0]}
                            tooltipActionCancel={{
                              title: `${t('button:cancel')}`
                            }}
                            tooltipActionSave={{
                              title: `${t('button:saveEnter')}`
                            }}
                            tooltipError={{
                              position: 'right',
                              align: 'start'
                            }}
                            destructiveText={
                              //@ts-ignore
                              formState?.errors?.phoneNumber?.[0]
                                ?.message as string
                            }>
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {data.permittedFields?.phoneNumber?.value &&
                              data.permittedFields?.phoneNumber?.value.length >
                                0 ? (
                                <div className="text-gray-900">
                                  {data.permittedFields.phoneNumber.value[0]}
                                </div>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:contactDetails:addPhoneNumber'
                                  )}
                                </div>
                              )}
                            </div>
                          </InlineEditingPhoneNumber>
                        </DisableControlByPermission>
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </div>
          </If>
        </>
      ) : null}

      {!configHide.location ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.location?.roles) ||
              permittedFields?.location
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="MapPin"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t('candidates:tabs:candidateOverview:contactDetails:location')}
              </TypographyText>
              <If
                condition={
                  permittedFields?.location?.client_user_visibility === false
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('tooltip:client_cannot_view')}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            <div>
              <div className="ml-2.5 w-full">
                <Controller
                  control={control}
                  name="locationWithStateID"
                  render={({ field: { onChange, value: location } }) => {
                    return (
                      <FormControlItem>
                        <DisableControlByPermission
                          applicantId={applicantId}
                          text={
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {location?.value ? (
                                <div className="text-gray-900">
                                  {location.value}
                                </div>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:notAvailable'
                                  )}
                                </div>
                              )}
                            </div>
                          }>
                          <InlineEditingNoActionsAsyncSingleSearchWithSelect
                            loadAsyncWhenOpen={false}
                            onChange={(newValue) => {
                              onChange(
                                newValue?.length ? newValue[0] : undefined
                              )
                              return submitPartialField(
                                'locationWithStateID',
                                trigger,
                                submit
                              )
                            }}
                            onCancelUpdate={(newValue) =>
                              onChange(
                                newValue?.length ? newValue[0] : undefined
                              )
                            }
                            promiseOptions={promiseCountryStateOptions}
                            placeholder={
                              t(
                                'candidates:tabs:candidateOverview:contactDetails:addLocation'
                              ) as string
                            }
                            size="sm"
                            classNameOverride={{
                              loadingMessage: `${t('label:loading')}`,
                              noOptionsMessage: `${t('label:noOptions')}`
                            }}
                            value={location?.value ? location : undefined}
                            menuIsOpen={locationMenuOpen}
                            onInputChange={(search) => {
                              if (search.length > 0) setLocationMenuOpen(true)
                              else setLocationMenuOpen(false)
                            }}>
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {location?.value ? (
                                <div className="text-gray-900">
                                  {location.value}
                                </div>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:contactDetails:addLocation'
                                  )}
                                </div>
                              )}
                            </div>
                          </InlineEditingNoActionsAsyncSingleSearchWithSelect>
                        </DisableControlByPermission>
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </div>
          </If>
        </>
      ) : null}

      {!configHide.links ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.links?.roles) ||
              permittedFields?.links
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Link"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t('candidates:tabs:candidateOverview:contactDetails:links')}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.links?.roles?.includes(ROLE.Everyone) &&
                  !permittedFields?.links?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.links?.client_user_visibility === false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.links?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            <div>
              <div className="ml-2.5 w-full">
                <Controller
                  control={control}
                  name="links"
                  render={({ field: { onChange } }) => {
                    return (
                      <FormControlItem>
                        <DisableControlByPermission
                          applicantId={applicantId}
                          text={
                            <div className="relative">
                              {permittedFields?.links?.value &&
                              Object.keys(permittedFields?.links?.value)
                                .length > 0 ? (
                                <div className="flex px-2 py-1.5">
                                  <InlineEditingViewLink
                                    source={permittedFields?.links?.value}
                                  />
                                </div>
                              ) : (
                                <div className="px-2 py-1.5 text-sm text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:notAvailable'
                                  )}
                                </div>
                              )}
                            </div>
                          }>
                          <InlineEditingInputLink
                            inlineMode={isDrawer ? 'drawer' : 'link'}
                            onChange={(newValue) => {
                              onChange({ value: newValue, _destroy: false })
                              return submitPartialField(
                                'links',
                                trigger,
                                submit
                              )
                            }}
                            onCancelUpdate={(newValue) =>
                              onChange({ value: newValue, _destroy: false })
                            }
                            placeholder={`${t(
                              'candidates:tabs:candidateOverview:contactDetails:addLinks'
                            )}`}
                            size="xs"
                            onDeleteLink={(value) => {
                              openAlert({
                                isPreventAutoFocusDialog: false,
                                className: 'w-[480px]',
                                title: `${t(
                                  'candidates:tabs:candidateOverview:link:deleteTitle'
                                )}`,
                                description: (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:link:deleteDescription"
                                    values={{
                                      link: value
                                    }}>
                                    <span className="font-medium text-gray-900" />
                                  </Trans>
                                ),
                                actions: [
                                  {
                                    label: `${t('button:cancel')}`,
                                    type: 'secondary',
                                    size: 'sm'
                                  },
                                  {
                                    isCallAPI: true,
                                    label: `${t('button:remove')}`,
                                    size: 'sm',
                                    onClick: async () => {
                                      onChange({ value, _destroy: true })
                                      await submitPartialField(
                                        'links',
                                        trigger,
                                        submit
                                      )
                                    }
                                  }
                                ]
                              })
                            }}
                            source={permittedFields?.links?.value}
                            isDisabledLinks={getDisabledLinks({
                              links: permittedFields?.links?.value
                            })}
                            tooltipActionCancel={{
                              title: `${t('button:cancel')}`
                            }}
                            tooltipActionSave={{
                              title: `${t('button:saveEnter')}`
                            }}
                            tooltipAddLinkButton={{
                              title: `${t(
                                'candidates:tabs:candidateOverview:contactDetails:maximumLinks',
                                { number: 15 }
                              )}`
                            }}
                            destructiveText={
                              formState?.errors?.links
                                ?.message as unknown as string
                            }
                          />
                        </DisableControlByPermission>
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </div>
          </If>
        </>
      ) : null}
    </div>
  )
}

export default ContactDetailsView
